import axios from 'axios';
import authHeader from './auth.header';

import AuthService from './auth.service';

import { API_BASE_URL } from '../utils/constants';

class TestService {
  getPublicContent() {
    axios.get(API_BASE_URL + 'public/test').then(res => console.log(res));
  }

  getSecuredContent() {
    axios.get(API_BASE_URL + 'app/test', { headers: authHeader() })
        .then(res => console.log(res))
        .catch(function (error) {
            if (error.response.status === 401) {
                AuthService.logout();
                window.location.href = "/";
            }
        });
  }
}

export default new TestService();