import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";

import './login.component.css';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        To pole jest obowiązkowe!
      </div>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          window.location.href = "/";
        },
        error => {
          const resMessage = error.response.status === 401 ? "Wprowadzone dane są nieprawidłowe" :
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
        <section id="cover" class="min-vh-100 login-page">
            <div id="cover-caption">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                            <h1 class="display-4 py-2">Zaloguj się do aplikacji</h1>
                            <div class="px-2">
                            <Form
                                onSubmit={this.handleLogin}
                                ref={c => {
                                this.form = c;
                                }}
                                class="justify-content-center"
                            >

                                    {this.state.message && (
                                    <div className="form-group">
                                        <div className="alert alert-danger" role="alert">
                                        {this.state.message}
                                        </div>
                                    </div>
                                    )}
                                    <CheckButton
                                    style={{ display: "none" }}
                                    ref={c => {
                                        this.checkBtn = c;
                                    }}
                                    />
                                    <div class="form-group">
                                        <label class="sr-only">Nazwa użytkownika</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            placeholder="Nazwa użytkownika"
                                            value={this.state.username}
                                            onChange={this.onChangeUsername}
                                            validations={[required]}
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label class="sr-only">Hasło</label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            placeholder="Hasło"
                                            value={this.state.password}
                                            onChange={this.onChangePassword}
                                            validations={[required]}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-primary btn-lg"
                                        disabled={this.state.loading}
                                    >
                                        {this.state.loading && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        <span>Zaloguj się</span>
                                    </button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }
}