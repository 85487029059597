import React, { Component } from "react";
import { Switch, Route, Link, Redirect} from "react-router-dom";
import Button from 'react-bootstrap/button';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Profile from "./components/profile.component";

import TestService from "./services/test.service";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    
    return (
      <div>
        <Button onClick={ TestService.getSecuredContent }>SECURED</Button>
        <Button onClick={ TestService.getPublicContent }>PUBLIC</Button>
        <div className="container">
          <Switch>
            {!this.state.currentUser && (
              <Route exact path="/login" component={Login} />
            )}
            {this.state.currentUser && (
              <Route exact path="/profile" component={Profile} />
              )}
                <Redirect to={ this.state.currentUser ? "/profile" : "/login"} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;